import React from 'react'
import styled from 'styled-components'

const CardContainer = styled.div`
  border-radius: 5px;
  box-shadow: 2px 2px 5px lightgray;
  padding: 1rem 0.5rem 1rem 0.5rem;
  min-width: 140px;
  text-align: center;
  height: 100%;
`;
export default CardContainer
