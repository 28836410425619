import React, { Component } from 'react';
import styled from 'styled-components';
import SEO from '../components/SEO';
import Layout from '../components/layout';
import { Link } from 'gatsby';
import jsLogo from '../../content/images/jslogo.svg';
import htmlLogo from '../../content/images/htmllogo.svg';
import cssLogo from '../../content/images/csslogo.svg';
import pythonLogo from '../../content/images/pythonlogo.svg';
import PlainButton from '../components/buttons/PlainBtn';
import SubjectCard from '../components/cards/SubjectCard';
import { media } from '../tokens';

const SubjectGrid = styled.main`
  display: grid;
  align-items: center;
  padding: 2rem;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(2, minmax(100px, 2fr));

  /* medium and larger screen sizes are 4 subjects per row */
  @media ${media.medium} {
    grid-template-columns: repeat(4, minmax(120px, 2fr));
    grid-gap: 1rem;
  }
`

const SubjectHeader = styled.h1`
  font-weight: 300;
  margin-bottom: 10px;
  font-size: 1.5rem;
`
const SubjectSubtitle = styled.h4`
  font-weight: 400;
`

const IntroPostsContainer = styled.section`
  padding: 1rem;
`

class HomePage extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="NowIsPow - Programming Tutorials for Students and Professionals"
          description="Knowledge is Power. Learn to write code for web and mobile apps from scratch. Read articles, code samples, and tutorials."
        />
        <SubjectGrid>
          <SubjectCard>
            <img src={htmlLogo} alt="HTML Logo" width="110" height="auto" />
            <SubjectHeader>Hypertext Markup Language</SubjectHeader>
            <SubjectSubtitle>The fundamental language of websites and apps</SubjectSubtitle>
            <hr />
            <Link to="/html">
              <PlainButton color="primary" text="Learn HTML" />
            </Link>
          </SubjectCard>
          <SubjectCard>
            <img src={cssLogo} alt="CSS Logo" width="79" height="auto" />
            <SubjectHeader>Cascading Style Sheets</SubjectHeader>
            <SubjectSubtitle>The language of beautiful design and layouts</SubjectSubtitle>
            <hr />
            <Link to="/css">
              <PlainButton color="primary" text="Learn CSS" />
            </Link>
          </SubjectCard>
          <SubjectCard>
            <img src={jsLogo} alt="JavaScript Logo" width="110" height="auto" />
            <SubjectHeader>JavaScript</SubjectHeader>
            <SubjectSubtitle>A cross-platform language for web superheroes</SubjectSubtitle>
            <hr />
            <Link to="/js">
              <PlainButton color="primary" text="Learn JavaScript" />
            </Link>
          </SubjectCard>
          <SubjectCard>
            <img src={pythonLogo} alt="Python language Logo" width="110" height="auto" />
            <SubjectHeader>Python</SubjectHeader>
            <SubjectSubtitle>The beginner-friendly Programming Language</SubjectSubtitle>
            <hr />
            <Link to="/python">
              <PlainButton color="primary" text="Learn Python" />
            </Link>
          </SubjectCard>
        </SubjectGrid>
      </Layout>
    );
  }
}

export default HomePage;
